module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Democratic community of Russian-speakers in Finland","short_name":"demcommunityfi.org","start_url":"/","background_color":"#ffffff","theme_color":"#2fa8e3","display":"standalone","icon":"logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"608d3e6aafb2f56886b73ce16788b605"},
    },{
      plugin: require('../node_modules/gatsby-plugin-addsocialshare-share/gatsby-browser.js'),
      options: {"plugins":[],"id":".ass_interface","providers":{"copylink":"Copy Link","facebook":"Facebook","telegram":"Telegram","twitter":"Twitter","whatsapp":"WhatsApp"},"interfacetype":"floating","alignment_desktop":"left","alignment_mobile":"bottom","size":48},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
