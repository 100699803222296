exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-en-tsx": () => import("./../../../src/pages/contacts.en.tsx" /* webpackChunkName: "component---src-pages-contacts-en-tsx" */),
  "component---src-pages-contacts-fi-tsx": () => import("./../../../src/pages/contacts.fi.tsx" /* webpackChunkName: "component---src-pages-contacts-fi-tsx" */),
  "component---src-pages-contacts-ru-tsx": () => import("./../../../src/pages/contacts.ru.tsx" /* webpackChunkName: "component---src-pages-contacts-ru-tsx" */),
  "component---src-pages-donations-en-tsx": () => import("./../../../src/pages/donations.en.tsx" /* webpackChunkName: "component---src-pages-donations-en-tsx" */),
  "component---src-pages-donations-fi-tsx": () => import("./../../../src/pages/donations.fi.tsx" /* webpackChunkName: "component---src-pages-donations-fi-tsx" */),
  "component---src-pages-donations-ru-tsx": () => import("./../../../src/pages/donations.ru.tsx" /* webpackChunkName: "component---src-pages-donations-ru-tsx" */),
  "component---src-pages-events-en-tsx": () => import("./../../../src/pages/events.en.tsx" /* webpackChunkName: "component---src-pages-events-en-tsx" */),
  "component---src-pages-events-fi-tsx": () => import("./../../../src/pages/events.fi.tsx" /* webpackChunkName: "component---src-pages-events-fi-tsx" */),
  "component---src-pages-events-ru-tsx": () => import("./../../../src/pages/events.ru.tsx" /* webpackChunkName: "component---src-pages-events-ru-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-fi-tsx": () => import("./../../../src/pages/index.fi.tsx" /* webpackChunkName: "component---src-pages-index-fi-tsx" */),
  "component---src-pages-index-ru-tsx": () => import("./../../../src/pages/index.ru.tsx" /* webpackChunkName: "component---src-pages-index-ru-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */)
}

